<template>
  <div
    v-if="userData"
    class="user-info mt-5"
  >
    <page-title :translation-params="[userData.name]">
      <a-button
        slot="custom-button"
        icon="arrow-left"
        type="link"
        @click="$router.back()"
      >
        {{ $t("button_statistics_go_back") }}
      </a-button>
    </page-title>

    <a-row
      v-if="userData"
      :gutter="[16, 16]"
    >
      <a-col
        :xs="24"
        :lg="18"
      >
        <a-card :title="$t('user_info_messages_count_by_days')">
          <VueApexCharts
            type="line"
            height="350"
            :options="msg_stat_chart_options"
            :series="msg_stat_series"
          />
        </a-card>

        <a-card
          v-if="referralData"
          class="mt-5"
          :title="$t('user_info_referrals_title')"
        >
          <user-referrals-table
            :referral="referralData"
            :data-gen-time="referralsDataGenTime"
          />
        </a-card>

      </a-col>
      <a-col
        :xs="24"
        :lg="6"
      >
        <a-card bordered>
          <div class="flex flex-col">
            <div class="flex flex-col items-center justify-center">
              <UserInfoAvatar :src="UserHelpers.getAvatarUrl(userData)" />

              <div class="flex mt-2 justify-between items-center w-full">
                <b> {{ userData.name || userData.login }} </b>
                <a-button
                  v-if="UserHelpers.getDirectLink(userData)"
                  type="primary"
                  icon="message"
                  :href="UserHelpers.getDirectLink(userData)"
                />
              </div>
            </div>
            <a-list-custom class="mt-2">
              <a-list-custom-item
                class="text-primary"
                :title="$t('user_info_about_title')"
              >
                <icon
                  slot="icon"
                  icon="account_box"
                />
              </a-list-custom-item>

              <a-list-custom-item
                :title="$t('user_info_user_id')"
                :subtitle="String(userData.user_id)"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>
              <a-list-custom-item
                :title="$t('user_info_user_login')"
                :subtitle="userData.login"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>

              <a-list-custom-item
                :title="$t('user_info_name')"
                :subtitle="userData.name"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>

              <a-list-custom-item
                :title="$t('user_info_language_code')"
                :subtitle="userData.language_code"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>

              <a-list-custom-item
                class="text-primary"
                :title="$t('user_info_reputation_title')"
              >
                <icon
                  slot="icon"
                  icon="thumbs_up_down"
                />
              </a-list-custom-item>

              <a-list-custom-item
                :title="$t('user_info_positive_reputation')"
                :subtitle="userData.positive_reputation ? String(userData.positive_reputation) : '0'"
                class="text-success"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>

              <a-list-custom-item
                :title="$t('user_info_negative_reputation')"
                :subtitle="userData.negative_reputation ? String(userData.negative_reputation) : '0'"
                class="text-danger"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>

              <a-list-custom-item
                class="text-primary"
                :title="$t('user_info_messages_title')"
              >
                <icon
                  slot="icon"
                  icon="chat_bubble"
                />
              </a-list-custom-item>
              <a-list-custom-item
                :title="$t('user_info_days_with_messages')"
                :subtitle="userData.days_with_messages ? String(userData.days_with_messages) : '0'"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>

              <a-list-custom-item
                :title="$t('user_info_message_count')"
                :subtitle="userData.message_count ? String(userData.message_count) : '0'"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>

              <a-list-custom-item
                class="text-primary"
                :title="$t('user_info_other_title')"
              >
                <icon
                  slot="icon"
                  icon="more"
                />
              </a-list-custom-item>

              <a-list-custom-item
                :title="$t('user_info_enter_time')"
                :subtitle="userData.enter_time ? String(userData.enter_time) : '-'"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>

              <a-list-custom-item
                :title="$t('user_info_days_in_chat')"
                :subtitle="userData.days_in_chat ? String(userData.days_in_chat) : '-'"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>

              <a-list-custom-item
                :title="$t('user_info_ban_level')"
                :subtitle="userData.ban_level ? String(userData.ban_level) : '0'"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>

              <a-list-custom-item
                :title="$t('user_info_warn_limit_count')"
                :subtitle="userData.warn_limit_count ? String(userData.warn_limit_count) : '0'"
              >
                <a-icon
                  slot="icon"
                  type="minus"
                />
              </a-list-custom-item>

            </a-list-custom>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-spin
      v-else
      class="w-full text-center"
    />
  </div>
</template>

<script>
import Api from "@/includes/logic/Api";
import { UserHelpers } from "@/includes/helpers/UserHelpers"
import UserInfoAvatar from "@/components/UserInfoAvatar"
import UserReferralsTable from '@/components/UserReferralsTable'
import { errorNotification } from "@/includes/NotificationService";
import PageTitle from '@/components/PageTitle'

import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";

import VueApexCharts from "vue-apexcharts";
import moment from 'moment'
import axios from 'axios'

export default {
  name: "user-info",
  components: {
    UserInfoAvatar,
    VueApexCharts,
    ConfigField,
    UserReferralsTable,
    AListCustom,
    AListCustomItem,
    Icon,
    PageTitle,
  },
  data() {
    return {
      referralsDataGenTime: null,
      referralData: null,
      msg_stat_series: [ {
        name: "Messages count",
        data: [],
      } ],
      msg_stat_chart_options: {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: false,
            }
          },
          type: 'line',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
        },
        labels: [],
        grid: {
          show: false,
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: true,
            style: {
              fontSize: '8px',
              fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          }
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '8px',
              fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
            formatter: (value) => parseInt(value),
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          }
        },
        tooltip: {
          x: { show: false },
          style: {
            fontSize: '12px',
            fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
            fontWeight: 400,
          }
        },
        legend: {
          fontSize: '12px',
          fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
          itemMargin: {
            vertical: 10,
          }
        }
      },

      userData: null,
      UserHelpers
    }
  },
  methods: {
    getUserStat() {
      Api.getUsersStat("tg", {
        chat_id: parseInt(this.$route.params[EntityTypes.CHAT_ID]),
        user_ids: [ parseInt(this.$route.params.userId) ]
      })
        .then(({ data }) => {
          this.userData = data.users[parseInt(this.$route.params.userId)]

          const from = moment(data.period_start);
          const to = moment(data.period_end);

          const periodDates = []

          while (!to.isBefore(from)) {
            periodDates.push(from.format('YYYY-MM-DD'))
            from.add('1', 'd')
          }

          const periodValues = new Array(periodDates.length).fill(0)

          if (this.userData.msg_stat && this.userData.msg_stat.length) {
            const msg_stat_values = periodValues.slice()
            this.userData.msg_stat.forEach(value => {
              msg_stat_values[periodDates.indexOf(value.time)] += value.count
            })
            this.msg_stat_series[0].data = msg_stat_values
            this.msg_stat_chart_options.labels = periodDates
          }

        })
    },
    getReferrals() {
      axios.get(`https://api.chatkeeper.app/cabinet/static/chatreferrals/${ this.$route.params[EntityTypes.CHAT_ID] }_34.json`)
        .then(({ data }) => {
          this.referralsDataGenTime = data.gentime
          this.referralData = data.users.filter((user) => user.user_id === parseInt(this.$route.params.userId))[0]
        })
        .catch(errorNotification)
    }
  },
  watch: {
    '$route.params.userId'() {
      this.getUserStat()
      this.getReferrals()
    }
  },
  mounted() {
    this.$baseTemplate.saveButton.hide()
  },
  created() {
    this.getUserStat()
    this.getReferrals()
  },
};
</script>

<style lang="scss">
.user-info {

}
</style>
